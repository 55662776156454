import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import useLanguage from "../hooks/use-language"
import { getNode } from "../helpers/graph-helper"
import CompanyHero from "../views/company/company-hero"
import GoldenISection from "../views/golden-i/section"
import IframeResizer from "iframe-resizer-react"
import { useRef } from "react"
import { useState } from "react"
import { Dialog, DialogContent } from "@material-ui/core"

const Foretag = ({ data }) => {
  const language = useLanguage()
  const iframeRef = useRef(null)
  let node = getNode(language.locale, data, "allGoldenIs")
  const [open, setOpen] = useState(false)
  const openDialog = () => {
    setOpen(true)
  }
  const closeDialog = () => {
    setOpen(false)
  }
  return (
    <>
      <Layout>
        <SEO title="Golden I" />
        <CompanyHero
          url={node.hero_background_image.url}
          title={node.hero_title}
        />
        <div style={{marginTop: '65px'}}></div>
        <GoldenISection title={node.title_1} text={node.description_1} />
        <GoldenISection
          title={node.title_2}
          text={node.description_2}
          invert
          onOpen={openDialog}
        />
      </Layout>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
        <DialogContent>
          <div style={{ height: "3000px" }}>
            {node && node.form && node.form.embed_url ? (
              <IframeResizer
                forwardRef={iframeRef}
                heightCalculationMethod="lowestElement"
                inPageLinks
                log
                src={node.form.embed_url}
                style={{
                  flex: 1,
                  width: "1px",
                  minWidth: "100%",
                  border: "none",
                  height: "100%",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export const query = graphql`
  {
    prismic {
      allGoldenIs {
        edges {
          node {
            _meta {
              lang
            }
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title
            title_1
            description_1
            title_2
            description_2
            form
          }
        }
      }
    }
  }
`

export default Foretag
