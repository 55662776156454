import React from 'react'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { breakpoints } from '../../../helpers/media'
import AttachedBackground from '../../../components/attached-background'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 65vh;
  @media only screen and (max-width: ${breakpoints.md}px) {
    height: 25vh;
  }
`

const H1 = styled.h1`
  color: white;
  font-size: 95px;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #000000be;
  opacity: 1;
  @media only screen and (max-width: ${breakpoints.md}px) {
    font-size: 45px;
  }
  transition: font-size 0.3s ease-in-out;
`

const CompanyHero = ({ url, title }) => {
  return (
    <>
      <AttachedBackground url={url}>
        <Wrapper>
          <H1>{RichText.asText(title)}</H1>
        </Wrapper>
      </AttachedBackground>
    </>
  )
}

export default CompanyHero
